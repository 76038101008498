<template>
    <div id="fit-window">
        <splitpanes 
            class="default-theme" 
            horizontal
            @resized="mapUpdatePageSize($event)"
        >
            <pane v-if="showMap" min-size="20" max-size="90" id="map-wrapper">
                <div id="mapContainer"></div>
            </pane>
            <pane>
                List
            </pane>
        </splitpanes>
    </div>
</template>

<script>
const butils = require('../libs/basicUtils.js');
const _ = butils.underscore;
const uuidv4 = butils.uuidv4;
const stringify = require('csv-stringify')
const fileDownload = require('js-file-download');

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import Image from '@/components/subcomponents/Image.vue'

export default {
    name: 'MapList', 
    components:{
        Splitpanes, 
        Pane,
        'hover-image-with-modal': Image,
    },
    props:{
        fetchEndpoint:{
            type: String
        },
        showMap:{
            type: Boolean,
            default: () => { return true; }
        },
    },
    data(){
        return{
            // Map Stuff
            tileProviders: [
                {
                    name: "MapBox - Light",
                    visible: true,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    url: "https://api.mapbox.com/styles/v1/trichards140/ckc2i4uee044v1ilzl8snvp2s/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ"
                },
                {
                    name: "MapBox - Satelite Streets V11",
                    visible: false,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    url: "https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ"
                },
                {
                    name: "MapBox - Streets V11",
                    visible: false,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ"
                }
                
            ],
            accessToken: "pk.eyJ1IjoidHJpY2hhcmRzMTQwIiwiYSI6ImNrYnllYzlicTBiY2IyeHQ2NGZreDU2ZjQifQ.PVvaJNTzI9zQReH1CYsteQ",
            mapID: null,
            map: null,
            zoom: 8,
            center: [27.936177185910104, -82.70507812500001],
            // Fetching Stuff
            fetchCompleted: false,
            
        }
    },
    methods:{
        mapUpdatePageSize(ignored){
            if(this.map != null){
                this.map.invalidateSize();
            }
        },
        zoomExtent(){
            this.map.fitBounds(this.leafGeoHandle.getBounds());
        },
        setupLeafletMap(){
            var baseMaps = {};
            var layers = [];
            this.tileProviders.forEach((tp)=>{
                var tmp = L.tileLayer(tp.url,{ attribution: tp.attribution})
                layers.push(tmp)
                baseMaps[tp.name] = tmp;
            })

            this.map = L.map('map-wrapper',{
                center: this.center,
                zoom: this.zoom,
                layers: [layers[0]]
            });
            L.control.layers(baseMaps).addTo(this.map);
            L.Control.ZoomExtent = L.Control.extend({
                options: {
                    position: 'topleft'
                },

                onAdd: ()=>{
                    var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control material-icons');

                    container.link = L.DomUtil.create('a', 'leaflet-bar-part', container);
                    container.link.title = 'Zoom Extent';
                    var userIcon = L.DomUtil.create('i', 'material-icons', container.link);
                    // console.log(userIcon);
                    userIcon.innerHTML = 'center_focus_strong';
                    userIcon.setAttribute('role', 'presentation');
                    container.link.href = '#';
                    container.link.setAttribute('role', 'button');

                    container.onclick = ()=>{
                        this.zoomExtent();
                    }
                    return container;
                }
            });
            var zoomExtentControl = new L.Control.ZoomExtent();
            this.map.addControl(zoomExtentControl);
        },
        performFetch(){
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}${this.fetchEndpoint}`)
            .then((response)=>{
                this.nonResStd = response.data.result.standard;
                this.nonResFire = response.data.result.fire;
                this.nonResIrr = response.data.result.irrigation;
                this.loading.nonResStd = false;
                this.loading.nonResFire = false;
                this.loading.nonResIrr = false;
            })
            .catch((err)=>{
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                }else{
                    console.log(err)
                    butils.createToast(this, "Failed Durring Dashboard Data Fetch", "Something went wrong while fetching the non residential service connection info", "danger", 30);
                }
                this.loading.nonResStd = false;
                this.loading.nonResFire = false;
                this.loading.nonResIrr = false;
            })
        }
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        this.performFetch();
        this.setupLeafletMap();
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
#fit-window{
    height: calc(100vh - 56px);
}

.splitpanes__pane {
  box-shadow: 0 0 3px rgba(0, 0, 0, .2) inset;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

em.specs {
  font-size: 0.2em;
  line-height: 1;
  position: absolute;
  color: #bbb;
  bottom: 0.5em;
  left: 0;
  right: 0;
  text-align: center;
}

</style>